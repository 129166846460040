import React from 'react';
import get from 'lodash.get';
import { isBlogEventEnded, isBlogLive } from 'lib/liveBlog';
import { heroLayoutType as heroLayoutTypePropType } from 'lib/CustomPropTypes';
import dayjs from 'dayjs';
import LiveFlag from 'components/LiveFlag';
import i18next from 'i18next';
import { TimestampWithUpdatedText } from 'components/TimeStampWithUpdatedText';
import PropTypes from 'prop-types';

export const StylizedTag = (props) => {
  const { latestDate, vertical, heroLayoutType } = props;
  // Need date value from an item to check
  if (!latestDate) {
    return null;
  }

  let label = i18next.t('EVENT ENDED');
  const flag = get(props, 'articles[0].flag', null);
  const dateCreated = get(props, 'articles[0].date.createdAt', null);
  const datePublished = get(props, 'articles[0].date.publishedAt', null);
  const eventEnded = isBlogEventEnded(flag) || !isBlogLive(latestDate);
  const isInitiallyPublished = dayjs(datePublished).diff(dateCreated, 's') <= 1;

  if (!eventEnded) {
    label = i18next.t(typeof flag === 'string' && flag.length ? flag : 'LIVE COVERAGE');
  }

  return (
    <LiveFlag
      label={label}
      heroLayoutType={heroLayoutType}
      timestampWithUpdatedText={(
        <TimestampWithUpdatedText {...{
          dateCreated,
          datePublished,
          latestDate,
          eventEnded,
          vertical,
          isInitiallyPublished,
        }}
        />
      )}
    />
  );
};

StylizedTag.propTypes = {
  vertical: PropTypes.string.isRequired,
  latestDate: PropTypes.string,
  heroLayoutType: heroLayoutTypePropType,
};

StylizedTag.defaultProps = {
  latestDate: '',
  heroLayoutType: {},
};
