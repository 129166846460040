import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import Head from 'next/head';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';

import { loadArticle } from 'redux/modules/article';
import { bulkUpdate as navUpdateAction } from 'redux/modules/navbar';
import {
  setOriginalCorrelationId,
  setPageView,
} from 'redux/modules/shared';

import {
  articleViewHandler,
  fetchCardByID,
  fetchLatestLiveBlogCardsByTaxonomyPath,
  CARD_QUERY_LIMITS,
} from 'lib/blogUtils';
import {
  ArticleContext,
  LiveBlogContext,
  FeatureFlagContext,
  LiveBlogCardsContext,
} from 'lib/ContextTypes';
import {
  article as ArticlePropType,
  liveBlogCard as LiveBlogCardPropType,
} from 'lib/CustomPropTypes';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import { getPathName } from 'lib/getPathName';
import LegacyIDService from 'lib/LegacyIDService';
import { isSocialCrawlBotUserAgent } from 'lib/isSocialCrawlBotUserAgent';
import { isBlogEventEnded, isBlogLive, shouldRenderRegularBlog } from 'lib/liveBlog';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { extractTermSlugsAsCommaSeparatedList, isElectionsTaxonomy } from 'lib/taxonomy';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { modifyIfUrl } from 'lib/urlUtils';
import { logError } from 'lib/datadog';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { ARTICLE_HERO_LAYOUT } from 'lib/brandFeatures';
import { getVideosFromLiveBlog } from 'lib/getVideosFromPayload';
import {
  useLiveBlogCards,
} from 'lib/Hooks/useLiveBlogCards';

import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import AMPHTMLLink from 'components/AMP/HTMLLink';
import WindowTopObserver from 'components/IntObserver/WindowTopObserver';
import { getLiveBlogMetadata } from 'components/PageMetadata/LiveBlogMetadata';
import { GlobalMetadata } from 'components/PageMetadata';
import ScrollingAnalytics from 'components/ScrollingAnalytics';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import IconfontStyleTag from 'components/IconfontStyleTag';
import UniversalCheckout from 'components/UniversalCheckout';
import { LiveBlogStacked } from 'components/LiveBlogStacked';
import { LiveBlogTwoColumn } from 'components/LiveBlogTwoColumn';
import { TopicSubNav } from 'components/TopicSubNav';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { VideoObjectLDScripts } from 'components/VideoObjectLDScripts';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import { VideoPlayerContext } from 'lib/ContextTypes/videoPlayer';
import { StylizedTag } from 'components/StylizedTag';
import globalContainerStyles from '../globalContainerStyles.module.scss';

import ErrorPage from '../_error';

dayjs.extend(relativeTime);
dayjs.extend(localeData);

const block = 'liveBlog';
const pageView = 'article';

// Export for test
export const navbarConfig = {
  theme: ({ vertical }) => {
    if (vertical === 'today') {
      return NAVBAR_THEME.LIGHT;
    }
    return NAVBAR_THEME.VERTICAL;
  },
  headline: ({ content }) => content?.headline ?? null,
  activeAt: ({ navbarContent, vertical }) => {
    const hasElectionsNav = isElectionsTaxonomy(navbarContent?.taxonomy?.primaryTopic?.slug);
    return (navbarContent?.subnav && !hasElectionsNav) || vertical === 'today'
      ? false
      : 160;
  },
  showShortcuts: ({ content }) => {
    const primaryTopic = content?.taxonomy?.primaryTopic;
    const electionYears = ['2020', '2022'];
    if (primaryTopic) {
      return !electionYears.some((year) => (
        [
          `${year}-election`,
          `elecciones-estados-unidos-${year}`,
        ].includes(extractTermSlugsAsCommaSeparatedList(primaryTopic))
      ));
    }
    return true;
  },
};

const mapStateToProps = ({
  article, shared, router,
}) => ({
  articles: article.content,
  isChromeless: shared.isChromeless,
  path: router.path,
});


export const getIsLiveBlogLive = ({ latestDate, flag }) => {
  // Need date value from an item to check
  if (!latestDate) {
    return false;
  }

  // Editor has entered "event ended" in the article flag
  // Else use last card publish date to determine live
  return isBlogEventEnded(flag) ? false : isBlogLive(latestDate);
};


export function LiveBlogPage(props) {
  const {
    card,
    isChromeless,
    path,
    post = null,
    vertical,
    layout,
    statusCode,
    userAgent,
    fullUrl,
    articles,
    liveBlogTaxonomyPath,
    liveBlogCardsData,
  } = props;

  const {
    'topic-subnav': isTopicSubnavEnabled,
    'hide-date-created-timestamp': hideCreatedTimestamp,
  } = useContext(FeatureFlagContext);
  const showCreatedDate = !(hideCreatedTimestamp ?? false);

  const [hasStickyVideo, setHasStickyVideo] = useState(false);

  const liveBlogCards = useLiveBlogCards({
    taxonomyPath: liveBlogTaxonomyPath,
    initialData: liveBlogCardsData,
    articleId: articles?.[0]?.id,
  });

  const {
    latestPublishedDate,
    activeItems,
    countNewItems: numberOfNewItems,
  } = liveBlogCards;

  const renderActiveLiveBlogFaviconLinks = (getIconLink) => (
    <>
      <link key="favicon" rel="shortcut icon" type="image/x-icon" href={getIconLink('favicon-active.ico')} />
      <link rel="icon" type="image/png" sizes="16x16" href={getIconLink('favicon-active-16x16.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={getIconLink('favicon-active-32x32.png')} />
      <link rel="icon" type="image/png" sizes="96x96" href={getIconLink('favicon-active-96x96.png')} />
    </>
  );

  const isLiveBlogLive = getIsLiveBlogLive({
    latestDate: latestPublishedDate,
    flag: articles?.[0]?.flag,
  });

  const liveBlogContextValue = useMemo(() => ({
    isLive: isLiveBlogLive,
    taxonomyPath: liveBlogTaxonomyPath,
  }), [isLiveBlogLive, liveBlogTaxonomyPath]);

  if (parseInt(statusCode, 10) >= 400) {
    return (
      <ErrorPage
        statusCode={statusCode === 404 ? 404 : 500}
        layout={layout}
      />
    );
  }

  const article = articles?.[0];

  if (!article) {
    return null;
  }

  const { subType, id } = article;

  const isRegularBlog = shouldRenderRegularBlog(subType);

  const articleMetaData = { items: activeItems, ...article };

  const headlineSource = (
    (
      card
      && !card.isHeadlineHidden
      && card.headline
    )
    || article.headline
  );
  const headlineText = headlineSource?.seo || headlineSource?.primary;
  const headlineCount = numberOfNewItems
    ? `(${(numberOfNewItems > 9 && '9+') || numberOfNewItems}) `
    : '';
  const headline = headlineCount + headlineText;

  const metaData = (
    <>
      {getLiveBlogMetadata({
        article: articleMetaData,
        card,
        cards: activeItems,
        vertical,
        isRegularBlog,
        cardOverrideId: post,
        userAgent,
        fullUrl,
      })}
      <Head>
        <title key="liveBlog-title">{headline}</title>
      </Head>
    </>
  );

  const hasElectionsNav = isElectionsTaxonomy(article?.taxonomy?.primaryTopic?.slug);
  const shouldShowSubNav = Boolean(article?.subnav && !hasElectionsNav && isTopicSubnavEnabled);
  const heroLayoutType = getFeatureConfigForBrand(ARTICLE_HERO_LAYOUT, vertical);
  useEffect(() => {
    // if canonical card page, we want to replace history back to the main live blog URL for the
    // purposes of preventing cannibalizing SEO
    if (card) {
      // scroll the card into view
      const cardContainer = document.getElementById(card.id);
      cardContainer?.scrollIntoView?.({ behavior: 'smooth' });

      // change the canonical card URL to the fragment url version
      const fragmentUrl = modifyIfUrl(window.location, (parsedUrl) => {
        /* eslint-disable no-param-reassign */
        parsedUrl.pathname = parsedUrl.pathname.replace(`/${card.id}`, '');
        parsedUrl.hash = card.id;
        parsedUrl.searchParams.delete('canonicalCard');
        /* eslint-enable no-param-reassign */
      });
      window.history.replaceState(null, '', fragmentUrl);
    }
  }, []);

  const content = (
    <WindowTopObserver
      threshold={0}
      callback={(event) => articleViewHandler(event, props)}
    >
      <ScrollingAnalytics contentType="liveBlog" contentUrl={article.url.primary}>
        <VideoPlayerContext.Provider value={{
          setStickyVideoFlag: setHasStickyVideo,
          isLiveBlog: true,
        }}
        >
          <ArticleContext.Provider value={article}>
            <LiveBlogContext.Provider value={liveBlogContextValue}>
              <LiveBlogCardsContext.Provider value={liveBlogCards}>
                <GlobalMetadata
                  webFaviconLinks={numberOfNewItems ? renderActiveLiveBlogFaviconLinks : null}
                />
                {metaData}
                <AMPHTMLLink
                  content={article}
                  isLiveBlog
                  vertical={vertical}
                />
                {shouldShowSubNav && (
                  <ErrorBoundary errorLogger={logError}>
                    <TopicSubNav
                      titleText={article.subnav.headline?.primary}
                      titleLink={article.subnav.externalUrl}
                      linkItems={article.subnav.content?.items}
                      isActiveLink={(item) => item.id === id}
                      logo={article.subnav.teaseImage}
                    />
                  </ErrorBoundary>
                )}
                {
                  article.presentation?.style === 'TWO_COLUMN'
                    ? (
                      <ErrorBoundary errorLogger={logError}>
                        <LiveBlogTwoColumn
                          article={article}
                          block={block}
                          path={path}
                          hasStickyVideo={hasStickyVideo}
                          isRegularBlog={isRegularBlog}
                          vertical={vertical}
                          isChromeless={isChromeless}
                          isLiveBlogLive={isLiveBlogLive}
                          showCreatedDate={showCreatedDate}
                          stylizedTag={(
                            <StylizedTag
                              latestDate={latestPublishedDate}
                              activeItems={activeItems}
                              {...props}
                            />
                          )}
                          latestPublishedCardDate={latestPublishedDate}
                        />
                      </ErrorBoundary>
                    )
                    : (
                      <ErrorBoundary errorLogger={logError}>
                        <LiveBlogStacked
                          article={article}
                          block={block}
                          path={path}
                          hasStickyVideo={hasStickyVideo}
                          isRegularBlog={isRegularBlog}
                          vertical={vertical}
                          isChromeless={isChromeless}
                          isLiveBlogLive={isLiveBlogLive}
                          showCreatedDate={showCreatedDate}
                          activeItems={activeItems}
                          heroLayoutType={heroLayoutType}
                          stylizedTag={(
                            <StylizedTag
                              heroLayoutType={heroLayoutType}
                              latestDate={latestPublishedDate}
                              activeItems={activeItems}
                              {...props}
                            />
                          )}
                          latestPublishedCardDate={latestPublishedDate}
                        />
                      </ErrorBoundary>
                    )
                }
              </LiveBlogCardsContext.Provider>
            </LiveBlogContext.Provider>
          </ArticleContext.Provider>
        </VideoPlayerContext.Provider>
      </ScrollingAnalytics>
    </WindowTopObserver>
  );

  const wrappedContent = isChromeless
    ? content
    : (
      <ErrorBoundary errorLogger={logError}>
        <HeaderAndFooter
          layout={layout}
          isNavbarSticky={!shouldShowSubNav}
        >
          {content}
        </HeaderAndFooter>
      </ErrorBoundary>
    );
  const allVideos = getVideosFromLiveBlog({ article, liveBlogCards: activeItems });

  return (
    <>
      <Head>
        <IconfontStyleTag />
      </Head>
      <VideoObjectLDScripts videos={allVideos} vertical={vertical} />
      <ThemesCssBundle vertical={vertical} />

      <div
        className={classNames(
          globalContainerStyles.container,
          'bg-knockout-primary',
        )}
        id="content"
      >
        {wrappedContent}
      </div>
      <AnalyticsLaunchScripts />
      <AdsBundle />
      <UniversalCheckout vertical={vertical} />
    </>
  );
}

LiveBlogPage.propTypes = {
  activeItems: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  articles: PropTypes.arrayOf(ArticlePropType).isRequired,
  card: PropTypes.objectOf(PropTypes.any),
  isChromeless: PropTypes.bool,
  layout: servicesLayoutPropType.isRequired,
  path: PropTypes.string,
  statusCode: PropTypes.number.isRequired,
  vertical: PropTypes.string.isRequired,
  post: PropTypes.string,
  userAgent: PropTypes.string.isRequired,
  fullUrl: PropTypes.string.isRequired,
  numberOfNewItems: PropTypes.number,
  liveBlogTaxonomyPath: PropTypes.string.isRequired,
  liveBlogCardsData: PropTypes.shape({
    pagination: PropTypes.shape({}),
    items: PropTypes.arrayOf(PropTypes.shape(LiveBlogCardPropType)),
  }).isRequired,
};

LiveBlogPage.defaultProps = {
  activeItems: [],
  card: null,
  isChromeless: false,
  path: '',
  post: undefined,
  numberOfNewItems: 0,
};

LiveBlogPage.getInitialProps = async (ctx) => {
  const {
    req: {
      params: {
        cardID,
        id: rawID,
      },
      query: {
        // page,
        post,
      },
    },
    res: {
      locals: {
        vertical,
        correlationId,
        fullUrl,
        logger,
        launchDarklyFlags,
      },
    },
    query: {
      userAgent,
    },
    store,
  } = ctx;

  const id = LegacyIDService.article(rawID);

  const firstPageCardLimit = (
    launchDarklyFlags?.['live-blog-card-query-limits']?.firstPage
    ?? CARD_QUERY_LIMITS.FIRST_PAGE
  );
  const firstPageCardLimitTwitter = (
    launchDarklyFlags?.['live-blog-card-query-limits']?.firstPageTwitter
    ?? CARD_QUERY_LIMITS.FIRST_PAGE_TWITTER
  );

  // TODO: BENTO-23429 add correlation ID to all Ramen logs
  await store.dispatch(setOriginalCorrelationId(correlationId));

  const initialPromiseArray = [
    store.dispatch(loadArticle(id)),
    store.dispatch(setPageView(pageView)),
  ];
  await Promise.all(initialPromiseArray);

  const {
    article: liveBlogAfterLoad,
  } = store.getState();

  const content = liveBlogAfterLoad?.content?.[0] ?? {};
  const body = content?.body ?? [];
  const headline = content?.headline ?? {};
  const primaryUrl = content?.url?.primary ?? '';
  const taxonomy = body.filter((el) => el.type === 'embeddedTaxonomy');
  const taxonomyPath = taxonomy?.[0]?.taxonomy?.path ?? false;

  const statusCode = liveBlogAfterLoad?.error?.status ?? 200;
  setLinkHeaders(ctx.res, vertical);

  const liveBlogCardsDataFetch = fetchLatestLiveBlogCardsByTaxonomyPath({
    taxonomyPath,
    queryLimit: isSocialCrawlBotUserAgent(userAgent)
      ? firstPageCardLimitTwitter
      : firstPageCardLimit,
    articleId: id,
  })
    .catch((e) => {
      logger.warn(e);
    });

  const cardDataFetch = cardID
    ? fetchCardByID(cardID)
      .catch((e) => {
        logger.warn(e);
      })
    : null;

  await store.dispatch(navUpdateAction({
    shareUrl: getPathName(primaryUrl),
    headline,
  }));

  return {
    navigating: false,
    pageView,
    statusCode,
    post,
    userAgent,
    fullUrl,
    liveBlogTaxonomyPath: taxonomyPath,
    liveBlogCardsData: await liveBlogCardsDataFetch,
    card: await cardDataFetch,
  };
};

export default navbar(navbarConfig)(
  connect(mapStateToProps)(
    LiveBlogPage,
  ),
);
